import axios from 'axios'
import authHeader from '../auth/auth-header'


const API_URL = 'https://development.space-meets.com/api/'

class TicketServiceApi {

    getAllTickets(params) {


        return axios.get(API_URL + 'admin/ticket/getTickets', {
            params: params,
            headers: authHeader()
        });
    }
    getTicketDetails(id) {
        return axios.get(API_URL + `admin/ticket/ticketDetail/${id}`, {
            headers: authHeader()
        });
    }
    updateTickeStatus(id, status,comment) {
        return axios.put(API_URL + `admin/ticket/updateTicket/${id}`, {
            status: status,
            comment:comment,

        },
            { headers: authHeader() });
    }


}

export default new TicketServiceApi()